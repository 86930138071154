import styles from "./index.module.scss";
import classNames from "classnames";
import Section from "components/Section";
import SectionItem from "components/Section/Item";
import { getLanguage } from "language/current";

interface IProps {
  className?: string;
}

export default function Products({ className = "" }: IProps) {
  const language = getLanguage();

  return (
    <Section
      title={language.products.title}
      className={classNames({
        [styles.Products]: true,
        [className]: true,
      })}
    >
      {language.products.list.map((product, index) => (
        <a href={product.link} target="_blank" rel="noreferrer" title="visitar site" key={index}>
          <SectionItem
            title={product.title}
            className={styles.product}
          >
						{/* <img src={product.image} alt={`Logo do ${product.title}`} /> */}
            {product.description.map((description, index) => (
              <p key={index}>{description}</p>
            ))}
          </SectionItem>
        </a>
      ))}
    </Section>
  );
}
