import { english } from "./english";
import { ILanguage } from "./language";
import { portuguese } from "./portuguese";

export function getLanguage(): ILanguage {
	const html = document.querySelector("html");
	const htmlTitle = document.querySelector("html head title");
	const htmlMetaDescription = document.querySelector("html head meta[name=description]");
	const htmlMetaKeywords = document.querySelector("html head meta[name=keywords]");

	if (navigator.language === 'pt-BR') {
		html?.setAttribute("lang", portuguese.site.language);
		htmlTitle!.innerHTML = portuguese.site.title;
		htmlMetaDescription?.setAttribute("content", portuguese.site.description);
		htmlMetaKeywords?.setAttribute("content", portuguese.site.keywords);
		return portuguese;
	}

	html?.setAttribute("lang", english.site.language);
	htmlMetaDescription?.setAttribute("content", english.site.description);
	htmlMetaKeywords?.setAttribute("content", english.site.keywords);
	htmlTitle!.innerHTML = english.site.title;
	return english;
}