import { ILanguage } from "./language";

export const portuguese: ILanguage = {
  site: {
    language: "pt-BR",
    title: "Barzano Sistemas - Desenvolvimento de software",
    description:
      "Site da Barzano Sistemas - Contratação desenvolvimento de sistemas",
    keywords:
      "Gian, Barzano, Sistemas, PDF, Extração, Software, Angular, React, Javascript, HTML, CSS, Sistema, Aplicativo",
  },
  header: {
    subtitle: "Desenvolvimento de software",
    callButton: {
      long: "Entrar em contato",
      short: "Contato",
    },
  },
  about: {
    title: "Sobre",
    description: [
      "A Barzano Sistemas, fundada em 2022, é uma empresa emergente no setor de tecnologia, com foco no desenvolvimento de software. Voltada para a inovação, a empresa está comprometida em fornecer soluções personalizadas e eficientes para atender às necessidades específicas de seus clientes.",
      "Em seus primeiros passos, a Barzano Sistemas busca consolidar sua presença no mercado, mantendo-se na vanguarda da tecnologia e estabelecendo parcerias sólidas para impulsionar o crescimento e o sucesso mútuo. Com uma visão orientada para o cliente e uma abordagem centrada na excelência, a empresa está determinada a se tornar uma referência no desenvolvimento de software, contribuindo para a transformação digital em diversas indústrias.",
    ],
  },
  products: {
    title: "Produtos",
    list: [
      {
        title: "Barzano Extract",
        link: "https://extract.barzano.com.br/",
				image: 'https://extract.barzano.com.br/assets/barzano-extract-logo.svg',
        description: [
          "Apresentamos o Barzano Extract, uma solução de software inovadora projetada para simplificar o processo de extração de dados de PDFs e várias outras fontes. Com seus algoritmos avançados e interface intuitiva, o Barzano Extract capacita as empresas a automatizar a extração de informações valiosas, economizando tempo e recursos.",
          "Seja para extrair dados financeiros de relatórios, analisar informações de clientes de formulários ou reunir insights de documentos de pesquisa, o Barzano Extract revoluciona a extração de dados, permitindo que as organizações tomem decisões mais rápidas e informadas. Diga adeus à entrada de dados manual e olá para a eficiência com o Barzano Extract.",
        ],
      },
      {
        title: "Futedle",
        link: "https://futedle.com.br/",
				image: 'https://futedle.com.br/assets/logos/futedle-emblema-logo.png',
        description: [
          "Descubra a emoção do futebol brasileiro com o nosso novo jogo de adivinhação diária! Teste seus conhecimentos sobre os times mais icônicos do Brasil enquanto desvenda pistas e curiosidades exclusivas.",
					"Com uma interface simples e envolvente, nosso jogo é perfeito para todos os amantes do futebol, desde os iniciantes até os especialistas. Desafie-se, aprenda mais sobre o universo do futebol brasileiro e divirta-se com a nossa experiência única de adivinhação!"
        ],
      },
      // {
      //   title: "Barzano Links",
      // 	link: 'https://links.barzano.com.br/',
      //   description: [
      //     "Apresentamos o Barzano Links, uma ferramenta especializada em manter o rastreamento do impacto dos componentes em seu projeto.",
      // 		"Com o Barzano Links, você pode acompanhar de perto cada elemento e sua influência no resultado final."
      //   ],
      // },
    ],
  },
  contacts: {
    title: "Contatos",
    cellphone: "Celular",
  },
};
