import { ILanguage } from "./language";

export const english: ILanguage = {
	site: {
		language: 'en',
		title: 'Barzano Sistemas - Software development',
		description: 'Barzano Sistemas site - Software development',
		keywords: 'Gian, Barzano, Sistemas, PDF, Extraction, Software, Angular, React, Javascript, HTML, CSS, System, app'
	},
  header: {
    subtitle: "Software development",
    callButton: {
      long: "Contact me",
      short: "Contact me",
    },
  },
  about: {
    title: "About",
    description: [
      "Barzano Sistemas, founded in 2022, is an emerging company in the technology sector, focusing on software development. Committed to innovation, the company is dedicated to providing customized and efficient solutions to meet the specific needs of its clients.",
      "In its early stages, Barzano Sistemas seeks to establish its presence in the market by staying at the forefront of technology and building strong partnerships to drive growth and mutual success. With a customer-oriented vision and a focus on excellence, the company is determined to become a reference in software development, contributing to digital transformation across various industries.",
    ],
  },
  products: {
    title: "Products",
    list: [
      {
        title: "Barzano Extract",
				link: 'https://extract.barzano.com.br/',
				image: 'https://extract.barzano.com.br/assets/barzano-extract-logo.svg',
        description: [
					"Introducing Barzano Extract, an innovative software solution designed to streamline the process of data extraction from PDFs and various other sources. With its advanced algorithms and intuitive interface, Barzano Extract empowers businesses to automate the extraction of valuable information, saving time and resources.",
					"Whether it's extracting financial data from reports, parsing customer information from forms, or gathering insights from research documents, Barzano Extract revolutionizes data extraction, enabling organizations to make faster, more informed decisions. Say goodbye to manual data entry and hello to efficiency with Barzano Extract.",
				],
      },
      {
        title: "Futedle",
        link: "https://futedle.com.br/",
				image: 'https://futedle.com.br/assets/logos/futedle-emblema-logo.png',
        description: [
          "Discover the thrill of Brazilian football with our new daily guessing game! Test your knowledge of Brazil's most iconic teams as you unravel clues and exclusive trivia.",
					"With a simple and engaging interface, our game is perfect for football enthusiasts of all levels, from beginners to experts. Challenge yourself, learn more about the Brazilian football universe, and enjoy our unique guessing experience!"
        ],
      },
      // {
      //   title: "Barzano Links",
			// 	link: 'https://links.barzano.com.br/',
      //   description: [
			// 		"Introducing Barzano Links, a specialized tool designed to keep track of the impact of components in your project.",
			// 		"With Barzano Links, you can closely monitor each element and its influence on the final outcome."
			// 	],
      // },
    ],
  },
  contacts: {
    title: "Contacts",
    cellphone: "Cellphone",
  },
};
