import classNames from "classnames";
import Section from "components/Section";
import styles from "./index.module.scss";
import { FaLinkedinIn, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { getLanguage } from "language/current";

interface IProps {
  className?: string;
}

export default function SideBar({ className = "" }: IProps) {
  const language = getLanguage();

  return (
    <aside
      className={classNames({
        [styles.SideBar]: true,
        [className]: true,
      })}
    >
      <div className={styles.Container}>
        <Section
          title={language.contacts.title}
          className={classNames({
            [styles.contacts]: true,
          })}
        >
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/barzano-sistemas"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn size={20} />
                <h3>Linkedin</h3>
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/5521965810054?text=Gostaria%20de%20agendar%20uma%20conversa"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp size={20} />
                <h3>{language.contacts.cellphone}</h3>
              </a>
            </li>
            <li>
              <a href="mailto:gian@barzano.com.br?subject=Proposta de trabalho&body=Olá, Gian! Gostaria de conversar sobre uma proposta de trabalho, qual o melhor horário para entrar em contato?">
                <FaEnvelope size={20} />
                <h3>E-mail</h3>
              </a>
            </li>
          </ul>
        </Section>
      </div>
    </aside>
  );
}
